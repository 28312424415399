<template>
	<tr class="row d-flex">
		<td
			:class="fieldName === firstItem ? ' borderless' : ''"
			class="font-weight-bold col-md-4">
			<span class="font-weight-bold">{{ fieldName }}</span>
		</td>
		<td
			:class="(['sm','xs'].includes(windowWidth) || fieldName === firstItem) ? ' borderless' : ''"
			class="col-md-4 px-0">
			<div v-if="!showEdit">
				<span
					v-if="booleanKeys.includes(keyValue)"
					class="text-muted border-0">
					{{ translate(`${!!fieldInformation.showable}`) }}
				</span>
				<span
					v-else-if="fieldLinks.includes(keyValue)"
					class="text-muted border-0 ">
					<a
						:href="fieldInformation.showable"
						target="_blank">{{ fieldInformation.showable }}</a>
				</span>
				<span
					v-else-if="allRoles.includes(fieldInformation.showable) || binaryOptions.includes(fieldInformation.showable)"
					class="text-muted border-0">
					{{ translate(fieldInformation.showable) }}
				</span>
				<span
					v-else
					class="text-muted border-0">
					{{ fieldInformation.showable }}
				</span>
				<duplicated-fields
					v-if="showDuplicableIcon && newSettingVal !== '' && newSettingVal !== null"
					:type="keyValue" />
				<span
					v-if="fieldInformation.editable && !showEdit && ['sm','xs'].includes(windowWidth)"
					class="btn btn-link float-right pointer"
					@click="activeEditMode">{{ translate('edit') }}</span>
			</div>
			<div
				v-if="showEdit"
				class="form-group mb-0">
				<select
					v-if="selectComponent.includes(keyValue)"
					:id="keyValue"
					v-model="newSettingVal"
					:class="errors.length > 0 ? 'is-invalid' : ''"
					:disabled="!Object.keys(options).length > 0 ? true : false"
					type="text"
					name="status"
					class="form-control">
					<option
						v-if="!Object.keys(options).length > 0 ? true : false"
						value="">
						{{ translate('loading') }}
					</option>
					<option
						v-if="keyValue === 'product'"
						:selected="newSettingVal === 'no_package'"
						value="no_package">
						{{ translate('no_package') }}
					</option>
					<template v-for="option in options">
						<option
							v-if="option!=='member'"
							:key="option"
							:value="option">
							{{ translate(option) }}
						</option>
					</template>
				</select>
				<textarea
					v-else-if="textAreaComponent.includes(keyValue)"
					:id="keyValue"
					v-model="newSettingVal"
					:class="errors.length > 0 ? 'is-invalid' : ''"
					rows="6"
					name="setting"
					class="form-control rounded-0 border-top-0 border-left-0 border-right-0 pt-1"
					@input="handleChange()" />
				<vue-tel-input
					v-else-if="telComponent.includes(keyValue)"
					:id="keyValue"
					v-model="newSettingVal"
					:placeholder="translate('enter_phone_number')"
					v-bind="bindProps"
					:class="errors.length > 0 ? 'is-invalid' : ''"
					name="setting"
					class="form-control rounded-0 border-top-0 border-left-0 border-right-0"
					@onInput="handleMobileChange" />
				<switch-toggle
					v-else-if="switchComponent.includes(keyValue)"
					id="switch-profile"
					v-model="newSettingVal"
					variant="success"
					pill
					class="mt-2"
					@change="handleChange()" />
				<v-date-picker
					v-else-if="dateComponent.includes(keyValue)"
					:id="keyValue"
					v-model="newSettingVal"
					:class="errors.length > 0 ? 'is-invalid' : ''"
					:input-props="{ class: 'form-control rounded-0 border-top-0 border-left-0 border-right-0 pt-1' + (errors.length > 0 ? ' is-invalid' : '') }"
					:formats="calendarConfig"
					name="setting"
					show-caps
					:popover="datepickerPopover"
					@input="handleChange()" />
				<div v-else-if="passwordComponent.includes(keyValue)">
					<input-password
						:id="keyValue"
						v-model="newSettingVal"
						:placeholder="keyValue === 'wallet_new_password' ? translate('new_wallet_password') : translate('password')"
						:input-class="`${errors.length > 0 ? 'is-invalid' : ''} form-control pt-1`"
						toggle-class="form-control pt-1"
						name="setting"
						only-bottom-border
						@input="handleChange()" />
					<template v-if="errors">
						<span
							v-for="error in errors"
							:key="error"
							class="invalid-feedback animated fadeIn text-wrap"
							v-text="error" />
					</template>
					<input-password
						:id="`${keyValue}_confirmation`"
						v-model="newSettingValConfirmation"
						:placeholder="keyValue === 'wallet_new_password' ? translate('new_wallet_password_confirmation') : translate('password_confirmation')"
						name="setting"
						input-class="form-control pt-1"
						toggle-class="form-control pt-1"
						only-bottom-border
						@input="handleChange()" />
				</div>
				<div v-else-if="payeeUsernameComponent.includes(keyValue)">
					<input
						:id="keyValue"
						v-model="newSettingVal"
						:class="errors.length > 0 ? 'is-invalid' : ''"
						type="text"
						name="setting"
						class="form-control rounded-0 border-top-0 border-left-0 border-right-0 pt-1"
						@input="handleChange()">
					<template v-if="errors">
						<span
							v-for="error in errors"
							:key="error"
							class="invalid-feedback animated fadeIn text-wrap"
							v-text="error" />
					</template>
					<div class="d-inline-flex">
						<switch-toggle
							id="switch-profile"
							v-model="newSettingSwitch"
							variant="success"
							pill
							class="mt-2"
							@change="handleChange()" />
						<p style="margin-top: 26px; margin-left: 5px;">
							{{ translate('change_payee_username') }}
						</p>
					</div>
				</div>
				<input
					v-else
					:id="keyValue"
					v-model="newSettingVal"
					:class="errors.length > 0 ? 'is-invalid' : ''"
					type="text"
					name="setting"
					class="form-control rounded-0 border-top-0 border-left-0 border-right-0 pt-1"
					@input="handleChange()">
				<template v-if="errors">
					<span
						v-for="error in errors"
						:key="error"
						class="invalid-feedback animated fadeIn text-wrap"
						v-text="error" />
				</template>
			</div>
		</td>
		<td
			:class="(['sm','xs'].includes(windowWidth) || fieldName === firstItem) ? ' borderless' : ''"
			class="col-md-4">
			<div v-if="fieldInformation.editable">
				<template v-if="showEdit">
					<span
						class="btn btn-primary float-right border-round-right pointer"
						@click="handleSave()">{{ translate('save') }}</span>
					<span
						class="btn btn-secondary float-right border-round-left pointer"
						@click="handleCancel()">{{ translate('cancel') }}</span>
				</template>
				<span
					v-if="!showEdit && !['sm','xs'].includes(windowWidth)"
					class="btn btn-link float-right edit-btn pointer"
					@click="activeEditMode">{{ translate('edit') }}</span>
			</div>
		</td>
		<td
			v-if="(!['sm','xs'].includes(windowWidth)) || (showEdit && ['sm','xs'].includes(windowWidth))"
			class="col borderless" />
	</tr>
</template>

<script>
import Vue from 'vue';
import { VueTelInput } from 'vue-tel-input';
import InputPassword from '@/components/InputPassword';
import DuplicatedFields from '@/components/DuplicatedFields';
import Switch from '@/components/Switch';
import WindowSizes from '@/mixins/WindowSizes';
import { VALID_COUNTRIES as availableCountries } from '@/settings/Country';
import { V_CALENDAR_CONFIG as calendarConfig } from '@/settings/Dates';
import {
	BOOLEAN_KEYS as booleanKeys, TRANSLATE_NO_EDIT as translateNoEdit,
	SWITCH_COMPONENT as switchComponent, TEL_COMPONENT as telComponent,
	TEXT_AREA_COMPONENT as textAreaComponent, SELECT_COMPONENT as selectComponent,
	FIELD_LINKS as fieldLinks, DATE_COMPONENT as dateComponent, PASSWORD_COMPONENT as passwordComponent,
	BINARY_PLACEMENT_OPTIONS as binaryOptions, PAYEE_USERNAME_COMPONENT as payeeUsernameComponent,
	SHOW_DUPLICATED_FIELDS as showDuplicatedFields,
} from '@/settings/Profile';
import { allRoles, admin } from '@/settings/Roles';
import {
	Profile, Packages, Ranks, Users, Common, Countries,
} from '@/translations';

const eventBus = new Vue();
export default {
	name: 'ProfileSettingField',
	messages: [Profile, Packages, Ranks, Users, Common, Countries],
	components: {
		InputPassword,
		'switch-toggle': Switch,
		VueTelInput,
		DuplicatedFields,
	},
	mixins: [WindowSizes],
	props: {
		fieldName: {
			type: String,
			default: '',
		},
		fieldInformation: {
			type: Object,
			default() {
				return {};
			},
		},
		errors: {
			type: Array,
			default() {
				return [];
			},
		},
		keyValue: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		maxChar: {
			type: Number,
			default: 0,
		},
		options: {
			type: Array,
			default() {
				return [];
			},
		},
		firstItem: {
			type: String,
			default: '',
		},
		datepickerPopover: {
			type: Object,
			default: () => ({}),
		},
		showDuplicatedInfo: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			editMode: false,
			newSettingVal: this.fieldInformation.value,
			newSettingValConfirmation: '',
			newSettingSwitch: false,
			active: false,
			booleanKeys,
			switchComponent,
			telComponent,
			textAreaComponent,
			selectComponent,
			dateComponent,
			passwordComponent,
			payeeUsernameComponent,
			translateNoEdit,
			fieldLinks,
			calendarConfig,
			allRoles,
			binaryOptions,
			showDuplicatedFields,
			bindProps: {
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			availableCountries,
			admin,
		};
	},
	computed: {
		showEdit() {
			if (this.editMode || this.errors.length > 0) {
				return true;
			}
			return false;
		},
		showDuplicableIcon() {
			return this.showDuplicatedInfo && this.showDuplicatedFields.includes(this.keyValue);
		},
	},
	watch: {
		newSettingVal(newVal) {
			if (this.maxChar > 0) {
				this.newSettingVal = newVal.slice(0, this.maxChar);
			}
		},
		newSettingValConfirmation(newValConfirm) {
			if (this.maxChar > 0) {
				this.newSettingValConfirmation = newValConfirm.slice(0, this.maxChar);
			}
		},
		newSettingSwitch(newVal) {
			if (this.maxChar > 0) {
				this.newSettingSwitch = newVal.slice(0, this.maxChar);
			}
		},
		editMode(newVal) {
			if (newVal === true) {
				this.setFocus(this.keyValue);
			}
		},
		loading(newVal) {
			if (!newVal && this.active) {
				this.setFocus(this.keyValue);
			}
		},
	},
	created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = this.availableCountries;
		eventBus.$on('editMode', this.handleEventBus);
	},
	beforeDestroy() {
		eventBus.$off('editMode');
	},
	methods: {
		handleCancel() {
			this.editMode = false;
			this.newSettingVal = this.fieldInformation.value;
			this.$emit('clearField');
		},
		handleChange() {
			this.editMode = true;
			this.$emit('clearField');
		},
		handleSave() {
			this.editMode = false;
			let fieldValue = this.newSettingVal;
			if (passwordComponent.includes(this.keyValue)) {
				fieldValue = {
					password: this.newSettingVal,
					passwordConfirmation: this.newSettingValConfirmation,
				};
			} else if (payeeUsernameComponent.includes(this.keyValue)) {
				fieldValue = {
					username: this.newSettingVal,
					changePayeeUsername: this.newSettingSwitch,
				};
			}
			this.$emit('editField', fieldValue);
		},
		handleMobileChange() {
			this.editMode = true;
			this.$emit('clearField');
		},
		activeEditMode() {
			this.editMode = true;
			eventBus.$emit('editMode', this.keyValue);
		},
		handleEventBus(key) {
			this.handleCancel();
			if (key === this.keyValue) {
				this.editMode = true;
				this.active = true;
			}
		},
	},
};
</script>

<style scoped>
	#switch-profile span.switch-slider {
		margin: 0 !important;
	}

	.table td {
		/*padding-top: unset;*/
		padding-bottom: unset;
	}

	.table td.borderless {
		border: 0px !important;
	}
	.table .row {
		margin-left: unset;
		margin-right: unset;
	}
	.table .row .btn.edit-btn {
		padding: unset;
	}
	.is-invalid ~ .invalid-feedback {
		display: block;
	}
</style>
